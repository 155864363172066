// ハンバーガーメニュー
$(".l-header_openbtn").click(function () {//ボタンがクリックされたら
    $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
    $("#l-header_nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    $(".circle-bg").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
});

$("#l-header_nav a").click(function () {//ナビゲーションのリンクがクリックされたら
    $(".l-header_openbtn").removeClass('active');//ボタンの activeクラスを除去し
    $("#l-header_nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
    $(".circle-bg").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
});

$(window).on('resize', function () {//ウィンドウサイズを変更時、ハンバーガーメニューの状態を解除
    let $windowWidth = window.innerWidth;
    if ($windowWidth > 745) {
        $(".l-header_openbtn").removeClass('active');//ボタンの activeクラスを除去し
        $("#l-header_nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
        $(".circle-bg").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
    };
});


// ページ内リンク設定
$(function () {
    var headerHeight = $('header').outerHeight();
    var urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({ scrollTop: position }, 500);
        }, 100);
    }
    $('a[href^="#"]').click(function () {
        var href = $(this).attr("href");
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({ scrollTop: position }, 500);
        return false;
    });
});

//inview
$(function () {
    $(".inview").on("inview", function (event, isInView) {
        if (isInView) {
            $(this).stop().addClass("is-show");
        }
    });
});

//メインビジュアルフェードイン
$(document).ready(function () {
    $('.l-mv_title,.l-mv_text,.l-mv_smooth').fadeIn(1500);
});

// ページトップアイコン(フェードイン)とページ内遷移スクロール
$(function () {
    var pagetop = $('.pagetop');
    // ボタンを非表示
    pagetop.hide();
    // 100pxスクロールしたらボタンを表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            pagetop.fadeIn();
        } else {
            pagetop.fadeOut();
        }
    });

    // ボタンをクリックしたら上へ
    pagetop.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});